import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

console.log(process.env.REACT_APP_API_KEY)

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "wesele-1f380.firebaseapp.com",
  projectId: "wesele-1f380",
  storageBucket: "wesele-1f380.appspot.com",
  messagingSenderId: "489361922838",
  appId: "1:489361922838:web:18acad22ca8a7f788a4e37"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export default app;