import React from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import map from '../map.jpg';
import map1 from '../map1.jpg';
import map2 from '../map2.jpg';

const Localization = () => {
  return (
    <Stack
      alignItems="stretch"
      justifyContent="space-between"
    >
      <Stack
        alignItems="stretch"
        sx={{
          flexDirection: 'column',
          p: 8,
          pb: 4,
          gap: 4,
        }}
      >
      <Divider
        sx={{
          "&::before, &::after": {
            borderColor: "#8e3a24",
          },
          "& span": {
            p: 0,
          }
        }}
      >
        <Box py={1} px={2} border="1px solid #8e3a24">
          <Typography color="#8e3a24" textTransform="uppercase" variant="h3" fontFamily="Times New Roman" letterSpacing="0.1em">
            Lokalizacja
          </Typography>
        </Box>
      </Divider>
      <Typography textAlign="center" fontFamily="Playfair Display">
        SALA WESELNA
      </Typography>
      <Typography textAlign="center" fontFamily="Playfair Display">
        Oklaski, Stanisławie 30A, 83-112 Lubiszewo
      </Typography>
      </Stack>
      <Box
        component="img"
        src={map}
        width="100%"
        sx={{
          display: 'none',
          '@media (min-width: 800px)': {
            display: 'block',
          },
        }}
      />
      <Box
        component="img"
        src={map1}
        width="100%"
        sx={{
          display: 'block',
          '@media (min-width: 800px)': {
            display: 'none',
          },
        }}
      />
      <Box
        component="img"
        src={map2}
        width="100%"
        sx={{
          display: 'block',
          '@media (min-width: 800px)': {
            display: 'none',
          },
        }}
      />
    </Stack>
  );
};

export default Localization;