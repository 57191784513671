import React, {useEffect, useState} from 'react';
import {Stack, Typography} from "@mui/material";
import moment from "moment";
import 'moment-precise-range-plugin';


const MainClock = () => {
  const weddingDate = moment("2023-07-23T15:00");

 const [difference, setDifference] = useState(moment.preciseDiff(moment(), weddingDate, true));

  useEffect(() => {
    const interval = setInterval(() => {
      setDifference(moment.preciseDiff(moment(), weddingDate, true));
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [weddingDate])

  const pad = (num: number) => {
    let temp = num.toString();
    while (temp.length < 2) temp = "0" + num;
    return temp;
  };

  return (
    <Stack
      height="100vh"
      bgcolor="#728463"
      justifyContent="center"
      alignItems="center"
      paddingX={6}
      sx={{
        paddingX: 3,
        '@media (min-width:600px)': {
          paddingX: 6,
        },
      }}
    >
      <Typography textTransform="uppercase" color="white" fontWeight={300} letterSpacing="0.8em" textAlign="center">
        Od 23 lipca 2023 roku minęło
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        maxWidth={1200}
        width="100%"
        textAlign="center"
        sx={{
          maxWidth: 400,
          '@media (min-width:600px)': {
            maxWidth: 800,
          },
          '@media (min-width:1200px)': {
            maxWidth: 1200,
          },
        }}
      >
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
              {pad(difference.years)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
              Lat
          </Typography>
        </Stack>
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
            {pad(difference.months)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
            Miesięcy
          </Typography>
        </Stack>
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
            {pad(difference.days)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
            Dni
          </Typography>
        </Stack>
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
            {pad(difference.hours)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
            godzin
          </Typography>
        </Stack>
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
            {pad(difference.minutes)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
            minut
          </Typography>
        </Stack>
        <Stack>
          <Typography fontFamily="Courier Prime" color="white" fontWeight={500} variant="h1">
            {pad(difference.seconds)}
          </Typography>
          <Typography textTransform="uppercase" color="white" sx={{opacity: 0.5}}>
            sekund
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MainClock;