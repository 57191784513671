import React from 'react';
import {Box, Stack, Typography} from "@mui/material";

const MainImage = () => {
  return (
    <Stack
      justifyContent="center"
      sx={{
        backgroundImage: `url(/main.jpg)`,
        width: '100%',
        height: "100vh",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Box height="50%"/>
      <Stack direction="row" justifyContent="space-around" textTransform="uppercase" color="white">
        <Box
          sx={{
            pt: 1,
            '@media (min-width:600px)': {
              pt: 4,
            },
          }}
        >
          <Typography variant="h2" fontFamily="Playfair Display">
            Grzegorz
          </Typography>
          <Typography textAlign="end" fontFamily="Playfair Display">
            Chamera
          </Typography>
        </Box>
        <Typography variant="h1">
          &
        </Typography>
        <Box
          sx={{
            pt: 1,
            '@media (min-width:600px)': {
              pt: 4,
            },
          }}
        >
          <Typography variant="h2" fontFamily="Playfair Display">
            Zuzanna
          </Typography>
          <Typography fontFamily="Playfair Display">
            Kowalska
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default MainImage;