import React, {useEffect, useState} from 'react';
import {
  Box, Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Modal, Paper, Stack, Typography
} from "@mui/material";
import {collection, getDocs, updateDoc, doc} from "firebase/firestore";
import {db} from "../utils/firebase";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {useNavigate} from "react-router-dom";


interface Game {
  id: string;
  name?: string;
  isSelected?: boolean;
  link?: string;
  expansions?: Game[],
}


const Games = () => {

  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    getDocs(collection(db, "gry")).then(response => {
      const res = response.docs.map(doc => {
        return getDocs(collection(db, `gry/${doc.id}/dodatki`)).then(expResponse => {
          return ({
            id: doc.id,
            ...doc.data(),
            expansions: expResponse.docs.map(expansion => ({id: expansion.id, ...expansion.data()}))
          });
        });
      })
      Promise.all(res).then(newGames => setGames(newGames));
      // console.log(response.docs.map(doc => ({id: doc.id,...doc.data()})));
    });
  }, [])

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedGame, setSelectedGame] = useState<Game>();

  return (
    <Box sx={{width: '100%', overflowY: 'auto'}}>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Paper sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 2,
        }}>
          <Typography mb={2}>
            Czy jesteś pewien, że chcesz odznaczyć grę: "{selectedGame?.name}"
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Button variant="contained" color="error" onClick={() => setIsModalOpen(false)}>
              Nie
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{
                bgcolor: "#728463",
              }}
              onClick={() => {
                selectedGame && updateDoc(doc(collection(db, 'gry'), selectedGame.id), {
                  isSelected: !selectedGame.isSelected,
                }).then(() => {
                  const i = games.findIndex(game => game.id === selectedGame.id);
                  const newGames = [...games];
                  newGames[i] = {...newGames[i], isSelected: !newGames[i].isSelected};
                  setGames(newGames);
                  setIsModalOpen(false);
                })
              }}>
              Tak
            </Button>
          </Stack>
        </Paper>
      </Modal>
      <Button
        sx={{
          mt: 2,
          ml: 2,
          '@media (min-width:400px)': {
            ml: 8,
          },
          '@media (min-width:800px)': {
            ml: 16,
          },
        }}
        onClick={() => {navigate('/')}} size="small" startIcon={<KeyboardBackspaceIcon />}
      >
        Powrót do głównej strony
      </Button>
      <Box m={2} sx={{
        '@media (min-width:400px)': {
          m: 8,
          mt: 4,
        },
        '@media (min-width:800px)': {
          m: 16,
          mt: 8,
        },
      }}>
        <Typography variant="h3" color="#728463">
          Lista gier, które sobie wymarzyliśmy
        </Typography>
        <Typography my={2} variant="h4">
          Instrukcja obsługi 😀
        </Typography>
        <List dense disablePadding>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText>
              Jeżeli ktoś z was chciałby kupić nam grę może anonimowo zaznaczyć ją na tej liście aby uniknąć powtórzonych prezentów.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText>
              Jeżeli gra jest już zaznaczona oznacza to, że została już przez kogoś wybrana.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText>
              Jeżeli to nie ty wybrałeś daną grę proszę nie odznaczaj jej.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText>
              Po prawej stronie każdej gry jest przycisk kierujący do strony, na której można ją obejrzeć i więcej się o niej dowiedzieć
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon>
            <ListItemText>
              Możecie nas również zaskoczyć i kupić nam grę, której na liście nie ma.
              Polecamy się wtedy skonsultować z naszymi świadkami Maćkiem lub Łucją aby zapytać czy już takiej nie mamy. 😀
            </ListItemText>
          </ListItem>
        </List>
        <Stack direction="row" justifyContent="center">
          <List sx={{ width: '100%', maxWidth: 800, bgcolor: 'background.paper' }}>
            {games.map(((game, i) => (
              <ListItem
                key={game.id}
                secondaryAction={
                  game.link ? <IconButton
                    edge="end"
                    aria-label="link"
                    onClick={() => {
                      const newWindow = window.open(
                        game.link,
                        '_blank',
                        'noopener,noreferrer'
                      )
                      if (newWindow) newWindow.opener = null;
                    }}
                  >
                    <OpenInNewOutlinedIcon />
                  </IconButton> : undefined
                }
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  onClick={() => {
                    if (game.isSelected) {
                      setSelectedGame(game)
                      setIsModalOpen(true);
                    } else {
                      updateDoc(doc(collection(db, 'gry'), game.id), {
                        isSelected: !game.isSelected,
                      }).then(() => {
                        const newGames = [...games];
                        newGames[i] = {...newGames[i], isSelected: !newGames[i].isSelected};
                        setGames(newGames);
                      })
                    }
                  }}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={game.isSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': game.id }}
                    />
                  </ListItemIcon>
                  <ListItemText id={game.id} primary={game.name} />
                </ListItemButton>
              </ListItem>
            ))).reduce((list: React.ReactElement | null, element, i) => {
              console.log(games[i]);
              console.log(games[i].name);
              console.log(games[i].expansions);
                return list === null ? (
                  element
                ) : (
                  <>
                    {list}
                    {element}
                    {games[i].isSelected && games[i].expansions?.map((expansion, expansionIndex) => (
                      <ListItem
                        key={expansion.id}
                        secondaryAction={
                          expansion.link ? <IconButton
                            edge="end"
                            aria-label="link"
                            onClick={() => {
                              const newWindow = window.open(
                                expansion.link,
                                '_blank',
                                'noopener,noreferrer'
                              )
                              if (newWindow) newWindow.opener = null;
                            }}
                          >
                            <OpenInNewOutlinedIcon/>
                          </IconButton> : undefined
                        }
                        disablePadding
                      >
                        <ListItemButton
                          role={undefined}
                          onClick={() => {
                            updateDoc(doc(collection(db, `gry/${games[i].id}/dodatki`), expansion.id), {
                              isSelected: !expansion.isSelected,
                            }).then(() => {
                              const newGames = [...games];
                              const newExpansions = [...newGames[i].expansions ?? []];
                              newExpansions[expansionIndex] = {
                                ...newExpansions[expansionIndex],
                                isSelected: !newExpansions[expansionIndex].isSelected
                              };
                              newGames[i] = {...newGames[i], expansions: newExpansions};
                              setGames(newGames);
                            })
                          }}
                          dense
                        >
                          <ListItemIcon sx={{pl: 10}}>
                            <Checkbox
                              edge="start"
                              checked={expansion.isSelected}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{'aria-labelledby': expansion.id}}
                            />
                          </ListItemIcon>
                          <ListItemText id={expansion.id} primary={expansion.name}/>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </>
                );
              }
              , null)}
          </List>
        </Stack>
      </Box>
    </Box>
  );
};

export default Games;