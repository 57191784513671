import React from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";


const When = () => {
  return (
    <Stack
      minHeight="100vh"
      bgcolor="#728463"
      sx={{
        flexDirection: 'column',
        '@media (min-width:1200px)': {
          flexDirection: 'row',
        },
        p: 8,
        gap: 8,
        '@media (min-width:800px)': {
          p: 16,
          gap: 16,
        },
      }}
    >
      <Box
        flexGrow={1}
        flexBasis={350}
        flexShrink={1}
        sx={{
          backgroundImage: `url(/propose.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 30%',
          '@media (min-width:1200px)': {
            backgroundPosition: 'center',
          },
        }}
      />
      <Box
        flexGrow={2}
        flexShrink={1}
        sx={{
          flexBasis: 0,
          '@media (min-width:1200px)': {
            flexBasis: 700,
          },
        }}
        display="flex"
        flexDirection="column"
      >
        <Box>
            <Divider
            sx={{
                "&::before, &::after": {
                  borderColor: "white",
                },
                "& span": {
                  p: 0,
                }
            }}
            >
                <Box py={1} px={2} border="1px solid white">
                    <Typography textTransform="uppercase" variant="h3" fontFamily="Times New Roman" letterSpacing="0.1em" color="white">
                      Harmonogram dnia
                    </Typography>
                </Box>
            </Divider>
        </Box>
        <Box color="white" flexGrow={1} mt={4} display="flex" flexDirection="column" justifyContent="center">
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3} mb={2}>
            Kościół pw. Chrystusa Zbawiciela, Osowa, Gdańsk
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
            15:00 Ceremonia Zaślubin
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3} mt={16} mb={2}>
            Oklaski, Stanisławie 30A, 83-112 Lubiszewo
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
            16:30 Cocktail Hour
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
                17:00 Obiad
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
                18:30 Pierwszy taniec
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
                20:00 Tort weselny
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
                21:30 Ciepły posiłek
            </Typography>
            <Typography fontFamily="Playfair Display" textAlign="center" lineHeight={3}>
                23:30 Oczepiny
            </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default When;