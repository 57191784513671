import React from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";


const Invite = () => {

  return (
    <Stack
      minHeight="100vh"
      gap={16}
      sx={{
        flexDirection: 'column',
        '@media (min-width:1200px)': {
          flexDirection: 'row',
        },
        p: 8,
        gap: 8,
        '@media (min-width:800px)': {
          p: 16,
          gap: 16,
        },
      }}
    >
      <Box
        sx={{
          flexBasis: 0,
          flexGrow: 0,
          '@media (min-width:1200px)': {
            flexBasis: 350,
            flexGrow: 1,
          },
        }}
      >
        <Box>
          <Divider color="#728463">
            <Typography textTransform="uppercase" variant="h3" fontFamily="Times New Roman" letterSpacing="0.1em" color="#728463">
              23 lipca 2023
            </Typography>
          </Divider>
          <Typography fontFamily="Playfair Display" lineHeight={2} pt={2} textAlign="justify">
            Serdecznie zapraszamy na nasz Ślub, który odbędzie się o godzinie 15:00 w kościele
            pod wezwaniem Chrystusa Zbawiciela na Osowej w Gdańsku. Po ceremonii
            zapraszamy na Wesele, które odbędzie się w sali „Oklaski” w Tczewie. Jeżeli potrzebujecie noclegu to dajcie znać.
          </Typography>
          <Box width="100%" my={4}>
            <Stack width="100%" direction="row" justifyContent="space-between">
              <Box
                sx={{
                  width: "20%",
                  pb: "20%",
                  position: "relative",
                  background: "#d3bcaf",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  width: "20%",
                  pb: "20%",
                  position: "relative",
                  background: "#8e3a24",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  width: "20%",
                  pb: "20%",
                  position: "relative",
                  background: "#728463",
                  borderRadius: "50%",
                }}
              />
              <Box
                sx={{
                  width: "20%",
                  pb: "20%",
                  position: "relative",
                  background: "#51704f",
                  borderRadius: "50%",
                }}
              />
            </Stack>
            <Typography
              fontFamily="Playfair Display"
              textAlign="right"
              fontSize="0.8rem !important"
              color="#9d9c9c"
              fontStyle="italic"
              mt={2}
            >
              kolorystyka dekoracji i sali będzie utrzymana w takich mnniej więcej barwach
            </Typography>
          </Box>
          <Typography fontFamily="Playfair Display" lineHeight={2} pt={2} textAlign="justify">
            Nie oczekujemy żadnych prezentów, chociaż w niedalekiej przyszłości pragnęlibyśmy
            kupić własne mieszkanie, więc jeśli chcielibyście nam pomóc osiągnąć trochę szybciej
            ten cel to oczywiście będzie nam bardzo miło. A jeśli jednak zależy Wam na symbolicznym
            bukiecie kwiatów to pewnie zostanie nam ich dużo po dekoracji sali weselnej, dlatego
            zamiast nich ucieszymy się z <Link to="/gry" style={{fontWeight: 600}}>gier planszowych</Link>
            , które oboje lubimy. Listę naszych wymarzonych gier możesz znaleźć <Link to="/gry" style={{fontWeight: 600}}>tutaj</Link>.
          </Typography>
        </Box>
      </Box>
      <Box
        flexGrow={1}
        flexBasis={350}
        flexShrink={0}
        sx={{
          backgroundImage: `url(/bench.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
    </Stack>
  );
};

export default Invite;