import React from 'react';
import {Box, createTheme, ThemeProvider} from '@mui/material';
import MainClock from "./components/MainClock";
import MainImage from "./components/MainImage";
import Invite from "./components/Invite";
import When from "./components/When";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Games from "./paths/Games";
import Localization from "./components/Localization";

function App() {
  return (
    <Box sx={{width: '100%'}}>
      <ThemeProvider theme={createTheme({
        palette: {
          primary: {
            main: "#728463",
          }
        },
        typography: {
          fontFamily: 'Lato, serif',
          body1: {
            fontSize: '0.7rem',
            '@media (min-width:400px)': {
              fontSize: '0.9rem',
            },
            '@media (min-width:800px)': {
              fontSize: '1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.2rem',
            },
            '@media (min-width:1400px)': {
              fontSize: '1.3rem',
            },
            '@media (min-width:1600px)': {
              fontSize: '1.5rem',
            },
          },
          h1: {
            fontSize: '2rem',
            '@media (min-width:400px)': {
              fontSize: '3rem',
            },
            '@media (min-width:600px)': {
              fontSize: '6rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '10rem',
            },
          },
          h2: {
            fontSize: '2rem',
            '@media (min-width:600px)': {
              fontSize: '3rem',
            },
            '@media (min-width:800px)': {
              fontSize: '4rem',
            },
            '@media (min-width:1000px)': {
              fontSize: '5rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '6rem',
            },
          },
          h3: {
            fontSize: '1rem',
            '@media (min-width:600px)': {
              fontSize: '1.2rem',
            },
            '@media (min-width:800px)': {
              fontSize: '1.5rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '2rem',
            },
            '@media (min-width:1400px)': {
              fontSize: '2.5rem',
            },
          },
          h4: {
            fontSize: '1rem',
            '@media (min-width:800px)': {
              fontSize: '1.2rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.5rem',
            },
            '@media (min-width:1400px)': {
              fontSize: '2rem',
            },
          }
        }
      })}>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route
                path="/"
                element={
                  <Box sx={{width: '100%', overflowY: 'auto'}}>
                     <MainClock />
                     <MainImage />
                     <Invite />
                     <When />
                    <Localization />
                   </Box>
                }
              />
            </Route>
            <Route path="/gry" element={<Games />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Box>
  );
}

export default App;
